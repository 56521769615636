.relative{
    position: relative;
}
.absolute{
    position: absolute;
}
.fixed{
    position: fixed;
}
/* flex */
.flex{
    display: flex;
}
.flex-align{
    display: flex;
    align-items: center;
}
.all-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.space-between{
    justify-content: space-between;
}
.space-around{
    justify-content: space-around;
}

.w-100{
    width: 100%;
}
.center{
    text-align: center;
}